import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ko"; // 한국어 언어 팩
import router from "@/router";
import VueApexCharts from "vue-apexcharts";
import { Bar, Line } from "vue-chartjs";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import vueCookies from "vue-cookies";
import axios from "axios";
import VueQrcodeReader from "vue-qrcode-reader";
import { Buffer } from "buffer";
import process from "process";
import VCalendar from 'v-calendar';
import 'firebase/compat/messaging'
import vClickOutside from 'vue-click-outside';
import VueI18n from 'vue-i18n';
import messages from './i18n/index';
import axiosURL, { setBaseURL } from './service/axiosUrl';

// 한국어 css
import("./assets/css/kr/default.css");
import("./assets/css/kr/hyh.css");
import("./assets/css/kr/yymim.css");
import("./assets/css/kr/jinmyeong.css");

// 중국어 css
// import("./assets/css/cn/default.css");
// import("./assets/css/cn/hyh.css");
// import("./assets/css/cn/yymim.css");
// import("./assets/css/cn/jinmyeong.css");

window.process = process;
window.Buffer = Buffer;
window.Kakao.init("ec213f32ca1b03805f693b468a79f75e");

document.addEventListener("contextmenu", e => {
  e.target.matches("img") && e.preventDefault()
})

Vue.config.productionTip = false;

Chart.register(CategoryScale);

Vue.component("bar-chart", Bar);
Vue.component("line-chart", Line);
Vue.component("apexchart", VueApexCharts);

Vue.use(VueQrcodeReader);
Vue.use(ElementUI, { locale });
Vue.use(VueApexCharts);
Vue.use(VCalendar);
Vue.use(vueCookies);
Vue.use(VueI18n);

Vue.directive('click-outside', vClickOutside);

Vue.prototype.$meta = {};
Vue.prototype.$axios = axios.create({ // Axios를 Vue 인스턴스에 글로벌로 설정
  headers: {
    'Access-Control-Allow-Credentials': true,
    'ngrok-skip-browser-warning': true,
  },
  // baseURL: process.env.VUE_APP_API_BASE_URL, // 로컬용
});
Vue.prototype.$axiosUrl = axiosURL;
Vue.prototype.$setBaseURL = setBaseURL;

new Vue({
  i18n: new VueI18n({
    legacy: false,
    locale: 'KO',
    //fallbackLocale: 'KO',
    messages : messages
  }),
  store,
  router,
  render: (h) => h(App),
  el: "#app",
  mounted() {
    this.setVh();
    window.addEventListener("resize", this.setVh);
  },
  methods: {
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setVh);
  },
}).$mount("#app");