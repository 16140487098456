import ko from '@/i18n/ko.json'
import ko_terms from '@/i18n/ko_terms.json'

import cn from '@/i18n/cn.json'
import cn_terms from '@/i18n/cn_terms.json'

import us from '@/i18n/us.json'
import us_terms from '@/i18n/us_terms.json'

export default {
    'KO' : { ...ko, ...ko_terms },
    'CN' : { ...cn, ...cn_terms },
    'US' : { ...us, ...us_terms },
}